import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FileDisplay from "../FileDisplay";

export interface IFileDraggableListElement {
  id: string;
  file: File;
}

interface IFileDraggableListProps {
  elements: IFileDraggableListElement[];
  onRemove: (file: IFileDraggableListElement) => void;
  onReorder: (elements: IFileDraggableListElement[]) => void;
}

const FileDraggableList = ({
  elements,
  onRemove,
  onReorder,
}: IFileDraggableListProps) => {
  const handleDragEnd = (result: any) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    // Reorder the elements
    const reorderedElements = [...elements];
    const [removed] = reorderedElements.splice(result.source.index, 1);
    reorderedElements.splice(result.destination.index, 0, removed);

    // Call the onReorder callback with the new order
    onReorder(reorderedElements);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="file-list">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="file-draggable-list flex flex-col gap-2"
          >
            {elements.map((element, index) => (
              <Draggable
                key={element.id}
                draggableId={element.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`file-draggable-item ${
                      snapshot.isDragging ? "dragging" : ""
                    }`}
                  >
                    <FileDisplay
                      draggable={true}
                      file={element.file}
                      onRemove={() => onRemove(element)}
                      removeText="Remove"
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FileDraggableList;
