import React, { DOMAttributes, ReactNode } from "react";
import classNames from "classnames";

interface IDescriptionPropTypes extends DOMAttributes<HTMLHeadingElement> {
  className?: string;
  children: ReactNode;
  color?: "base" | "danger";
}

const Description = ({
  className,
  children,
  color = "base",
  ...otherProps
}: IDescriptionPropTypes) => {
  return (
    <h6
      className={classNames([
        className,
        "description text-sm font-normal mb-2",
        color === "base" ? "text-text-placeholder" : "text-danger-500",
      ])}
      {...otherProps}
    >
      {children}
    </h6>
  );
};

export default Description;
