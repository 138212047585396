import Dots3Icon from "../../Icons/Dots3Icon";

const FileDisplay = ({
  draggable,
  file,
  onRemove,
  removeText,
}: {
  draggable?: boolean;
  file: File;
  onRemove: () => void;
  removeText: string;
}) => (
  <div className="flex justify-between items-center rounded-md bg-gray-100 p-2">
    <div className="flex items-center justify-start gap-2">
      {draggable && (
        <div className="flex items-center justify-center w-6 h-6 text-text-placeholder">
          <Dots3Icon />
        </div>
      )}
      <span>{file.name}</span>
    </div>
    <button className="text-red-500 text-sm" onClick={onRemove}>
      {removeText}
    </button>
  </div>
);

export default FileDisplay;
