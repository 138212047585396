import { useContext, useMemo } from "react";
import { ModelGalleryContext } from "./context";

export const useModelGallery = () => {
  const context = useContext(ModelGalleryContext);

  if (!context) {
    throw new Error(
      "useModelGallery must be used within a ModelGalleryProvider"
    );
  }

  const nextImage = () => {
    const currentIndex = context.images.findIndex(
      (image) => image.id === context.currentImage?.id
    );
    if (currentIndex === context.images.length - 1) {
      context.setCurrentImage(context.images[0]);
      return;
    }
    context.setCurrentImage(context.images[currentIndex + 1]);
  };

  const previousImage = () => {
    const currentIndex = context.images.findIndex(
      (image) => image.id === context.currentImage?.id
    );
    if (currentIndex === 0) {
      context.setCurrentImage(context.images[context.images.length - 1]);
      return;
    }
    context.setCurrentImage(context.images[currentIndex - 1]);
  };

  const nextImageAvailable = context.images.length > 1;

  const previousImageAvailable = context.images.length > 1;

  const currentImageIndex = useMemo(() => {
    return context.images.findIndex(
      (image) => image.id === context.currentImage?.id
    );
  }, [context.images, context.currentImage]);

  const totalImages = useMemo(() => {
    return context.images.length;
  }, [context.images]);

  return {
    ...context,
    currentImageIndex,
    totalImages,
    nextImage,
    previousImage,
    nextImageAvailable,
    previousImageAvailable,
  };
};
