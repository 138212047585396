import React, { useEffect, useState } from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MessageService } from "../../../../../services/MessageService";
import DropZone from "../../../../shared/components/DropZone";
import Title from "../../../../shared/typography/Title";
import { IModelUploadContainer } from "../interface";
import { withRouter } from "react-router-dom";
import ModelNameInputGroup from "../components/ModelNameInputGroup";
import { FormGroup } from "../../../../shared/form/FormGroup";
import Button from "../../../../shared/buttons/Button";
import { BrowseType, Model } from "../../../../../models/Model";
import ModelService from "../../../services/ModelService";
import { ModelFlightLogService } from "../../../services/ModelFlightLogService";
import { useGlobalEventEmitter } from "../../../../shared/context/GlobalEventEmitter";
import { EventTypes } from "../../../../../const/events";
import { ModelUploadFileValidator } from "../utils/ModelUploadFileValidator";
import { SubscriptionType } from "../../../../payments/payments.model";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import Description from "../../../../shared/typography/Description";
import FileDisplay from "../../../../shared/components/FileDisplay";
import FileDraggableList, {
  IFileDraggableListElement,
} from "../../../../shared/components/FileDraggableList";

interface IUniversalUploadPropTypes extends IModelUploadContainer {
  interiorChecked?: boolean;
  defaultModelName?: string;
}

const UniversalUpload = ({
  interiorChecked,
  defaultModelName,
  onLoad,
  VideoUploadStore,
  GlobalStore,
}: IUniversalUploadPropTypes) => {
  const { user } = useCurrentUser();
  const eventEmitter = useGlobalEventEmitter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [videoFilesList, setVideoFiles] = useState<IFileDraggableListElement[]>(
    []
  );
  const [logsFile, setLogsFile] = useState<File | null>(null);
  const [modelName, setModelName] = useState(
    defaultModelName || (t("upload.defaultUniversalModelName") as string)
  );

  useEffect(() => {
    setModelName(
      defaultModelName || (t("upload.defaultUniversalModelName") as string)
    );
  }, [defaultModelName, setModelName, t]);

  const resetState = () => {
    setVideoFiles([]);
    setLogsFile(null);
    setModelName(defaultModelName);
  };

  const onDrop = async (files: File[], type: "video" | "gps") => {
    if (!files.length) return;

    const subscriptionType = user.organization.active
      ? user.organization.subscription.type.value
      : SubscriptionType.Freemium;
    const result = ModelUploadFileValidator(files, subscriptionType);

    if (result.sizeExceededFiles.length) {
      let errorMessage: string;
      switch (subscriptionType) {
        case SubscriptionType.Freemium:
          errorMessage = "upload.freemiumSizeExceeded";
          break;
        case SubscriptionType.Lite:
          errorMessage = "upload.liteSizeExceeded";
          break;
        case SubscriptionType.Premium:
          errorMessage = "upload.premiumSizeExceeded";
          break;
      }
      MessageService.error(t(errorMessage));
      return;
    }

    if (type === "video") {
      if (result.videoFiles.length) {
        const newVideoFiles = result.videoFiles.filter(
          (file) =>
            !videoFilesList.some((f) => f.file.name === file.name) &&
            file.type.startsWith("video/")
        );
        setVideoFiles([
          ...videoFilesList,
          ...newVideoFiles.map((file) => ({
            id: file.name,
            file,
          })),
        ]);
      } else {
        MessageService.error(t("toastWrongExtensionCount"));
      }
    } else if (type === "gps") {
      if (result.logsFiles.length) {
        const logsFile = result.logsFiles[result.logsFiles.length - 1];
        const validExtensions = [".srt", ".ass", ".txt", ".csv"];
        const fileExtension =
          "." + logsFile.name.split(".").pop()?.toLowerCase();
        if (validExtensions.includes(fileExtension)) {
          setLogsFile(logsFile);
        }
      } else {
        MessageService.error(t("toastWrongExtensionCount"));
      }
    }
  };

  const uploadLogs = async (model: Model) => {
    // Obtaining presigned url
    const { presignedUrl } = await ModelFlightLogService.getPresignedUrlForLogs(
      model.id,
      {
        fileName: logsFile.name.toLocaleLowerCase(),
      }
    );

    const blob = new Blob([logsFile], { type: logsFile.type });
    await axios.put(presignedUrl, blob, {
      headers: {
        "Content-Type": logsFile.type,
      },
    });
  };

  const handleSubmit = async () => {
    if (!videoFilesList.length) {
      MessageService.error(t("pleaseSelectVideoFile"));
      return;
    }

    onLoad(true);
    setLoading(true);

    try {
      let model: Model;
      const missionStartedAt = new Date();

      for (const videoListElement of videoFilesList) {
        const videoFile = videoListElement.file;
        if (!model) {
          model = await createModel({ videoFile, missionStartedAt });
        } else {
          await ModelService.createPart(
            model.id,
            videoFile.lastModified,
            videoFile.size,
            videoFile.name
          );
        }

        VideoUploadStore.addToQueue(
          videoFile,
          videoFile.name,
          model.id,
          String(model.inputs[0].id)
        );
      }

      eventEmitter.trigger(EventTypes.ModelCreated, model);
      if (logsFile) await uploadLogs(model);
      GlobalStore.uploadDialogOpen = false;
      resetState();
    } catch (error) {
      MessageService.error(t("requestFailed"));
    } finally {
      setLoading(false);
      onLoad(false);
    }
  };

  const createModel = async ({
    videoFile,
    missionStartedAt,
  }: {
    videoFile: File;
    missionStartedAt: Date;
  }): Promise<Model> => {
    const res = await ModelService.createModel({
      name: modelName,
      browseType: interiorChecked ? BrowseType.Interior : BrowseType.Universal,
      videoSize: videoFile?.size,
      external: true,
      droneManufacturer: undefined,
      droneType: undefined,
      missionStartedAt: missionStartedAt.getTime(),
    });
    return res.data as Model;
  };

  return (
    <div className="flex flex-col mb-4">
      <FormGroup>
        <ModelNameInputGroup value={modelName} onChange={setModelName} />
      </FormGroup>
      <FormGroup>
        <Title className="upload-title">
          {t("chooseUniversalUploadFilesTitle")}
        </Title>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="upload-column flex flex-col">
            <h4 className="mb-2">
              {t("chooseUniversalUploadFilesTitleDescription")}
            </h4>
            {videoFilesList.length ? (
              <div className="flex flex-col gap-2">
                <FileDraggableList
                  elements={videoFilesList}
                  onRemove={(file) =>
                    setVideoFiles(
                      videoFilesList.filter((f) => f.id !== file.id)
                    )
                  }
                  onReorder={(elements) => setVideoFiles(elements)}
                />
                <Description color="danger" className="text-danger-500">
                  Please ensure videos are in the correct sequence - this is
                  essential for proper model processing
                </Description>
              </div>
            ) : (
              <>
                <DropZone
                  onDrop={(files) => onDrop(files, "video")}
                  backgroundColor="white"
                  multiple={true}
                />
                <Description>{t("allowedVideoExtensions")}</Description>
              </>
            )}
          </div>
          <div className="upload-column flex flex-col">
            <h4 className="mb-2">{"GPS File (optional)"}</h4>
            {logsFile ? (
              <FileDisplay
                file={logsFile}
                onRemove={() => setLogsFile(null)}
                removeText={t("Remove")}
              />
            ) : (
              <>
                <DropZone
                  onDrop={(files) => onDrop(files, "gps")}
                  backgroundColor="white"
                  multiple={true}
                />
                <Description>{t("allowedGPSExtensions")}</Description>
              </>
            )}
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="flex justify-end">
          <Button
            loading={loading}
            disabled={!videoFilesList.length || loading}
            onClick={handleSubmit}
          >
            {t("upload")}
          </Button>
        </div>
      </FormGroup>
    </div>
  );
};

export default withRouter(
  inject("GlobalStore", "VideoUploadStore")(observer(UniversalUpload))
);
